$mempink: #f24266ff;
$primary: $mempink;
$title-color: $mempink;
$primary-background: #FFFCF6EC;

@import '../node_modules/bulma/bulma.sass';
@import '../node_modules/bulma-calendar/src/sass/index.sass';
@import url('https://fonts.googleapis.com/css2?family=Arbutus+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$subtitle-color: $grey-light;

@media screen and (min-width: 1025px) {
  .navbar-item.is-hoverable:hover .navbar-dropdown {
      display: block !important;
  }
  .navbar-item.is-hoverable:focus-within .navbar-dropdown {
      display: none;
  }
}

body {
  height: 100%;
  background-color: $primary-background;
}

.main-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

.footer {
  background-color: $primary-background;
  padding: 1.5rem;
  text-align: center;
}

.footer-link {
  color: $primary;
}

.logo-subtitle {
  font-family: "Arbutus Slab", serif;
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.memreel-oneliner {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
}

.store-links img {
  height: 44px;
  width: auto;
}
